// Generated by Framer (236a21b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./cdZMzeQ_2-0.js";

const cycleOrder = ["C8BuN2eu0"];

const serializationHash = "framer-xctgz"

const variantClassNames = {C8BuN2eu0: "framer-v-oge47b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {U_5FWB8bN: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({color2, height, id, link, text, width, ...props}) => { return {...props, DCgngo3AD: link ?? props.DCgngo3AD, kO916IM3R: color2 ?? props.kO916IM3R ?? "var(--token-989f2e76-73d4-44be-8fd0-e246e326736d, rgb(249, 250, 251))", VtfcG0KWb: text ?? props.VtfcG0KWb ?? "Help Center"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;link?: string;color2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VtfcG0KWb, DCgngo3AD, kO916IM3R, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "C8BuN2eu0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={DCgngo3AD} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-oge47b", className, classNames)} framer-3bgqfu`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"C8BuN2eu0"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1yZWd1bGFy", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-letter-spacing": "-0.01875em", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-kO916IM3R-cdZMzeQ_2))"}}>Help Center</motion.p></React.Fragment>} className={"framer-3w87il"} data-framer-name={"Help Center"} fonts={["FS;Satoshi-regular"]} layoutDependency={layoutDependency} layoutId={"ppTBq2BuZ"} style={{"--extracted-r6o4lv": "var(--variable-reference-kO916IM3R-cdZMzeQ_2)", "--framer-paragraph-spacing": "0px", "--variable-reference-kO916IM3R-cdZMzeQ_2": kO916IM3R}} text={VtfcG0KWb} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xctgz.framer-3bgqfu, .framer-xctgz .framer-3bgqfu { display: block; }", ".framer-xctgz.framer-oge47b { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-xctgz .framer-3w87il { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xctgz.framer-oge47b { gap: 0px; } .framer-xctgz.framer-oge47b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-xctgz.framer-oge47b > :first-child { margin-left: 0px; } .framer-xctgz.framer-oge47b > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"VtfcG0KWb":"text","DCgngo3AD":"link","kO916IM3R":"color2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercdZMzeQ_2: React.ComponentType<Props> = withCSS(Component, css, "framer-xctgz") as typeof Component;
export default FramercdZMzeQ_2;

FramercdZMzeQ_2.displayName = "Footer List Item";

FramercdZMzeQ_2.defaultProps = {height: 24, width: 81};

addPropertyControls(FramercdZMzeQ_2, {VtfcG0KWb: {defaultValue: "Help Center", displayTextArea: false, title: "Text", type: ControlType.String}, DCgngo3AD: {title: "Link", type: ControlType.Link}, kO916IM3R: {defaultValue: "var(--token-989f2e76-73d4-44be-8fd0-e246e326736d, rgb(249, 250, 251)) /* {\"name\":\"Surface\"} */", title: "Color 2", type: ControlType.Color}} as any)

addFonts(FramercdZMzeQ_2, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/TTX2Z3BF3P6Y5BQT3IV2VNOK6FL22KUT/7QYRJOI3JIMYHGY6CH7SOIFRQLZOLNJ6/KFIAZD4RUMEZIYV6FQ3T3GP5PDBDB6JY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})